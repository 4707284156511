.Button {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    margin-left: 8px;
    margin-right: 5px;
    padding-top: 5px;
    border: 1px solid black;
    text-align: center;
    display: inline-block;
    width: 20px;
}

.questionBlock {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.Button:hover {
    cursor: pointer;
}

.radioBlock {
    margin-left: 20px;
    margin-top: 5px;
}

.ButtonValidate {
    background-color: #f8b100;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: 8px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 1px solid black;
    margin-top: 5%;
}

.ButtonValidate:hover {
    cursor: pointer;
}

.ButtonValidate:active {
    background-color: #eb5e1a;
}

.errorModal {
    position: fixed;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -90%);
    transform: translate(-50%, -90%);
    width: 50%;
    text-align: center;
    background-color: #f59604;
    padding-top: 5%;
    padding-bottom: 5%;
}

.validationBlack {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.validationModal {
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -90%);
    transform: translate(-50%, -90%);
    width: 50%;
    text-align: center;
    background-color: #f59604;
    padding-top: 5%;
    padding-bottom: 5%;
}

.dualBlock {
    display: inline-block;
    width: 49%;
}
.leftQuestion3 {
    display: inline-block;
     vertical-align: top; 
     margin-left: 20px;
     border-right: 3px solid black;
     padding-right: 20px;
     width:45%;
}
.rightQuestion3 {
    display: inline-block;
     margin-left: 20px;
     width:45%;
}

@media only screen and (max-width: 1400px) {
    .Button {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 5px;
        margin-left: 2px;
        margin-right: 2px;
        padding-top: 3px;
        text-align: center;
        display: inline-block;
        width: 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .dualBlock {
        display: block;
        width: auto;
        margin-right: 20px;
    }
    .Button {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 5px;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 4px;
        text-align: center;
        display: inline-block;
        width: 15px;
    }
    .leftQuestion3 {
        display: block;
         margin-left: 30px;
         border-right: 0px;
         width:auto;
         margin-top: 10px;
    }
    .rightQuestion3 {
        display: block;
         margin-left: 30px;
         width:auto;
         margin-top: 10px;
    }
    .textArea {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90% !important;
    }
    .space {
        padding-top: 50px;
    }
}
@media only screen and (max-width: 850px) {
    
    .Button {
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 5px;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 4px;
        text-align: center;
        display: inline-block;
        width: 15px;
    }
}